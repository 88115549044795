<template>
  <div>
    <b-card title="Detalhes do seu perfil">
      <hr>
      <div>
      <b-row>
      <p>Usuario:&nbsp;</p>
      <p class="user-name font-weight-bolder mb-0">
      {{usuario.nomeusu}}
      </p>
      </b-row>
      <b-row>
      <p>Email:&nbsp;</p>
      <p class="user-name font-weight-bolder mb-0">
      {{usuario.email}}
      </p>
      </b-row>
      <b-row>
       <br> Tema: <dark-Toggler class="d-soon d-lg-block" />
        </b-row>
      <br>
      <div class="DivBotaoLadoLado">
         <b-avatar
            size="150"
            :src="image"
          />
        </div>
          <div class="DivBotaoLadoLado">
          <img id="qrCode" 
          :src="generateQRCodeUrl(usuario.aD_CHAVEPONTO) + '&v=' + Math.random()"
           alt="qrCode Não Localizado"
          >
        </div>
      </div>
      
    </b-card>

     <b-card title="Data Protection Officer (DPO)">
      

       <hr>
       <b-row>
      <p>Nome:&nbsp;</p>
      <p class="user-name font-weight-bolder mb-0">
      Gilmar Machado
      </p>
      </b-row>
      <b-row>
      <p>Telefone:&nbsp;</p>
      <p class="user-name font-weight-bolder mb-0">
       41 3362-6466
      </p>
      </b-row>
      <b-row>
      <p>Email:&nbsp;</p>
      <p class="user-name font-weight-bolder mb-0">
       dpo@vapza.com.br
      </p>
      </b-row>
     </b-card>

  </div>
</template>

<script>
import { BCard, BRow, BAvatar } from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BCard,
    BRow,
    BAvatar,
    // Navbar Components
    DarkToggler,
  },
  data() {
    return {
      usuario: '',
      image: '',
    }
  },
  mounted() {
    // Valida Session Ativa
    if (this.$session.exists('usuario')) {
      this.usuario = this.$session.get('usuario')
      this.image = 'data:image/jpg;base64,'.concat(this.image.concat(this.usuario.foto))
    }
  },
  methods: {
    generateQRCodeUrl(valor) {

      if(valor != '' && valor != null){
        return `https://zxing.org/w/chart?cht=qr&chs=230x230&chld=L&choe=UTF-8&chl=${valor}`;
      }
    }
  }
}
</script>

<style>

</style>
